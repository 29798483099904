export default [
  {
    path: "/stands",
    name: "ListStand",
    component: () => import("@/views/stand/list-stand.vue"),
    meta: {
      pageTitle: "menu_name.space",
      breadcrumb: [
        {
          text: "menu_name.metaverse",
          active: true,
        },
        {
          text: "menu_name.stand_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/stands/:id",
    name: "Single-Stand",
    component: () => import("@/views/stand/single-stand.vue"),
    meta: {
      pageTitle: "Nome dello Stand",
      breadcrumb: [
        {
          text: "menu_name.metaverse",
          active: true,
        },
        {
          text: "menu_name.stand_list",
          to: "/stands",
          active: false,
        },
        {
          text: "menu_name.edit",
          active: true,
        },
      ],
    },
  },
];
