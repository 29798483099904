export default [
  {
    path: "/meeting_points",
    name: "ListPoints",
    component: () => import("@/views/meeting-point/meeting-points-list.vue"),
    meta: {
      pageTitle: "menu_name.meeting_point",
      breadcrumb: [
        {
          text: "menu_name.metaverse",
          active: true,
        },
        {
          text: "menu_name.meeting_point_list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/meeting_points/:id",
    name: "Single-Point",
    component: () => import("@/views/meeting-point/single-meeting-point.vue"),
    meta: {
      pageTitle: "menu_name.meeting_point",
      breadcrumb: [
        {
          text: "menu_name.metaverse",
          active: true,
        },
        {
          text: "menu_name.meeting_point_list",
          to: "/meeting_points",
          active: false,
        },
        {
          text: "menu_name.edit",
          active: true,
        },
      ],
    },
  },
];
