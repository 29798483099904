export default {
  state: {
    rangeDate: null,
    list: []
  },
  mutations: {
    set_date (state, rangeDate) {
      state.rangeDate = rangeDate;
    },
    set_list (state, list) {
      state.list = list;
    }
  },
}