import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import i18n from "@/libs/i18n";

import Vueditor from "vueditor";
import "vueditor/dist/style/vueditor.min.css";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/clipboard";
import "@/libs/sweet-alerts";

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(far, fab, fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import VueFontAwesomePicker from "vfa-picker";
Vue.use(VueFontAwesomePicker);

import JsonCSV from "vue-json-csv";
Vue.component("downloadCsv", JsonCSV);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

let config = {
  toolbar: [
    "removeFormat",
    "undo",
    "|",
    "fontName",
    "fontSize",
    "foreColor",
    "backColor",
    "divider",
    "bold",
    "italic",
    "underline",
    "strikeThrough",
    "link",
    "divider",
    "subscript",
    "superscript",
    "divider",
    "justifyLeft",
    "justifyCenter",
    "justifyRight",
    "justifyFull",
    "|",
    "indent",
    "outdent",
    "insertOrderedList",
    "insertUnorderedList",
    "|",
    "picture",
    "|",
    "sourceCode",
  ],
  fontName: [
    { val: "arial black" },
    { val: "times new roman" },
    { val: "Courier New" },
  ],
  fontSize: [
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "28px",
    "32px",
    "36px",
  ],
  uploadUrl: "",
};
Vue.use(Vueditor, config);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
