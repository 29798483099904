export default [
  {
    path: "/statistics",
    name: "stats",
    component: () => import("@/views/statistics/statistics.vue"),
    meta: {
      pageTitle: "menu_name.statistics",
      breadcrumb: [
        {
          text: "menu_name.account",
          active: true,
        },
        {
          text: "menu_name.statistics",
          active: true,
        },
      ],
    },
  },
  {
    path: "/statistics/stand/:id",
    name: "Statistics-Single-Stand",
    component: () =>
      import("@/views/statistics/statistics-single-stand/single-stand.vue"),
    meta: {
      pageTitle: "Nome dello Stand",
      breadcrumb: [
        {
          text: "menu_name.account",
          active: true,
        },
        {
          text: "menu_name.statistics",
          to: "/statistics",
          active: false,
        },
        {
          text: "menu_name.space",
          active: true,
        },
      ],
    },
  },
];
