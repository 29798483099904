export default {
  state: {
    setting: null,
  },
  mutations: {
    set_setting(state, setting) {
      state.setting = setting;
    },
    unset_setting(state) {
      state.setting = null;
    },
  },
};
