import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import stand from "./routes/stand";
import meetingpoints from "./routes/meeting-point";
import statistics from "./routes/statistics";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...stand,
    ...meetingpoints,
    ...statistics,
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
      },
    },
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register/:code",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full",
        auth: false,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/hall",
      name: "hall",
      component: () => import("@/views/hall/Hall.vue"),
      meta: {
        pageTitle: "menu_name.hall",
        breadcrumb: [
          {
            text: "menu_name.metaverse",
            active: true,
          },
          {
            text: "menu_name.hall",
            active: true,
          },
        ],
      },
    },
    {
      path: "/totem",
      name: "totem",
      component: () => import("@/views/totem/totem.vue"),
      meta: {
        pageTitle: "menu_name.pop_up_banner",
        breadcrumb: [
          {
            text: "menu_name.metaverse",
            active: true,
          },
          {
            text: "menu_name.personalization",
            active: true,
          },
          {
            text: "menu_name.pop_up_banner",
            active: true,
          },
        ],
      },
    },
    {
      path: "/press-room",
      name: "press-room",
      component: () => import("@/views/press-room.vue"),
      meta: {
        pageTitle: "menu_name.press_room",
        breadcrumb: [
          {
            text: "menu_name.metaverse",
            active: true,
          },
          {
            text: "menu_name.press_room",
            active: true,
          },
        ],
      },
    },
    {
      path: "/conference",
      name: "conference",
      component: () => import("@/views/conference/conference.vue"),
      meta: {
        pageTitle: "menu_name.webinar_live",
        breadcrumb: [
          {
            text: "menu_name.metaverse",
            active: true,
          },
          {
            text: "menu_name.events",
            active: true,
          },
          {
            text: "menu_name.webinar_live",
            active: true,
          },
        ],
      },
    },
    {
      path: "/personalizations",
      name: "personalizations",
      component: () => import("@/views/personalization/personalizations.vue"),
      meta: {
        pageTitle: "menu_name.poster",
        breadcrumb: [
          {
            text: "menu_name.metaverse",
            active: true,
          },
          {
            text: "menu_name.personalization",
            active: true,
          },
          {
            text: "menu_name.poster",
            active: true,
          },
        ],
      },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("@/views/notification/notifications.vue"),
      meta: {
        pageTitle: "menu_name.notication",
        breadcrumb: [
          {
            text: "menu_name.preferences",
            active: true,
          },
          {
            text: "menu_name.notication",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/users/visitors.vue"),
      meta: {
        pageTitle: "menu_name.attendees",
        breadcrumb: [
          {
            text: "menu_name.metaverse",
            active: true,
          },
          {
            text: "menu_name.attendees",
            active: true,
          },
        ],
      },
    },
    {
      path: "/editors",
      name: "editors",
      component: () => import("@/views/users/editors.vue"),
      meta: {
        pageTitle: "menu_name.users",
        breadcrumb: [
          {
            text: "menu_name.account",
            active: true,
          },
          {
            text: "menu_name.users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/support",
      name: "support",
      component: () => import("@/views/support.vue"),
      meta: {
        pageTitle: "Supporto",
        breadcrumb: [
          {
            text: "menu_name.preferences",
            active: true,
          },
          {
            text: "Supporto",
            active: true,
          },
        ],
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("@/views/settings/settings.vue"),
      meta: {
        pageTitle: "menu_name.settings",
        breadcrumb: [
          {
            text: "menu_name.account",
            active: true,
          },
          {
            text: "menu_name.settings",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tutorials",
      name: "tutorials",
      component: () => import("@/views/tutorials.vue"),
      meta: {
        pageTitle: "menu_name.tutorial",
        breadcrumb: [
          {
            text: "menu_name.help_center",
            active: true,
          },
          {
            text: "menu_name.tutorial",
            active: true,
          },
        ],
      },
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
