import { api } from "./index";

export const initSettings = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`settings/init`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const getSettings = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`settings`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const saveSettings = (formData) => {
  formData.append("_method", "PUT");
  return new Promise((resolve, reject) => {
    api
      .post(`settings`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};
