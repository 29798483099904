export default {
    state: {
        user: null,
    },
    mutations: {
        set_user (state, user) {
            state.user = user;
        },
        unset_user (state) {
            state.user = null;
        }
    },
}